import { container, input, label } from "./input.module.scss"
import React from "react"
import { v4 as uuid } from "uuid"

function Input(props) {
  let { inputName, children, className, ...propsObject } = props
  let classes = input + " " + className
  let inputId = uuid()
  return (
    <div className={container}>
      <label className={label} htmlFor={inputId}>
        {inputName}
      </label>
      <input {...propsObject} className={classes} id={inputId}>
        {children}
      </input>
    </div>
  )
}

export default Input
