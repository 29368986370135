import { container, label, textarea } from "./textarea.module.scss"
import React from "react"
import { v4 as uuid } from "uuid"

function Textarea(props) {
  let { inputName, children, className, ...restProps } = props
  let classes = container + " " + className
  let inputId = uuid()
  return (
    <div className={`${container} ${classes}`}>
      <label className={label} htmlFor={inputId}>
        {inputName}
      </label>
      <textarea {...restProps} className={textarea} id={inputId}>
        {children}
      </textarea>
    </div>
  )
}

export default Textarea
