import React, { createRef } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import Input from "../input"
import {
  form,
  container,
  button,
  column,
  messageError,
  messageSent,
  textarea,
} from "./contactForm.module.scss"
import Textarea from "../textarea"
import Button from "../button"
import Popup from "reactjs-popup"

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.refForm = createRef()
    this.state = { open: false, popupContent: "", status: "" }
    this.closeModal = () =>
      this.setState({ open: false, popupContent: "", status: "" })
    this.openModal = (content, state) =>
      this.setState({ open: true, popupContent: content, status: state })
  }

  render() {
    const { intl } = this.props
    const buttonLabel = intl.formatMessage({ id: "contact.sendMessage" })
    const nameLabel = intl.formatMessage({ id: "contact.nameInputLabel" })
    const emailLabel = intl.formatMessage({ id: "contact.emailInputLabel" })
    const objectLabel = intl.formatMessage({ id: "contact.objectInputLabel" })
    const messageLabel = intl.formatMessage({ id: "contact.messageInputLabel" })

    return (
      <form
        action="https://formspree.io/f/maylbdly"
        method="POST"
        className={form}
        ref={this.refForm}
      >
        <div className={container}>
          <div className={column}>
            <Input type="text" name="fullname" inputName={nameLabel} />
            <Input type="email" name="email" inputName={emailLabel} />
            <Input type="text" name="object" inputName={objectLabel} />
            <Textarea
              className={textarea}
              name={"message"}
              inputName={messageLabel}
            />
            <input type="text" name="_gotcha" style={{ display: "none" }} />
          </div>
        </div>
        <Button className={button} onClick={this.triggerSubmit.bind(this)}>
          {buttonLabel}
        </Button>
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
        >
          <div className={this.state.status}>{this.state.popupContent}</div>
        </Popup>
      </form>
    )
  }

  triggerSubmit(ev) {
    const { intl } = this.props
    ev.preventDefault()
    const sentMessageLabel = intl.formatMessage({ id: "contact.sentMessage" })
    const sendingErrorMessageLabel = intl.formatMessage({
      id: "contact.sendingErrorMessage",
    })
    const form = this.refForm.current
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        this.openModal(sentMessageLabel, messageSent)
      } else {
        this.openModal(sendingErrorMessageLabel, messageError)
      }
    }
    xhr.send(data)
  }
}

export default injectIntl(ContactForm)
