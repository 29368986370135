import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import ContactForm from "../components/contactForm"
import { ContactPage } from "../components/enum/pages"
import SEO from "../seo"
import Layout from "../layout/layout"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Map from "../components/map"
import LinearBlock from "../layout/linearblock/linearblock"

import {
  contactItem,
  iconDesktop,
  iconMobile,
  contactItemContent,
  columnContainer,
  map,
  contacts,
} from "../styles/contact.module.scss"
import {
  layoutContainer,
  imageContainer,
  imageTop,
  overlayLogo,
  textOnImage,
  subtitle,
} from "../styles/home.module.scss"

import logoTransparent from "../assets/images/logo-blanc.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons"

export const pageQuery = graphql`
  query {
    strapiContactpage {
      headerimage {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
const ContactItem = props => (
  <div className={contactItem}>
    <FontAwesomeIcon
      className={iconDesktop}
      fixedWidth
      size={"1x"}
      icon={props.icon}
      color={"black"}
    />
    <FontAwesomeIcon
      className={iconMobile}
      fixedWidth
      size={"1x"}
      icon={props.icon}
      color={"black"}
    />
    <div className={contactItemContent}>{props.children}</div>
  </div>
)

const ContactInfo = props => (
  <div className={columnContainer}>
    <Map className={map} />
    <div className={contacts}>
      <ContactItem icon={faPaperPlane}>
        <a href={"mailto:contact@sylvide.fr"}>contact@sylvide.fr</a>
      </ContactItem>
      <ContactItem icon={faPhoneAlt}>
        <a href={"tel:04.77.64.34.71"}>04.77.64.34.71</a>
      </ContactItem>
      <ContactItem icon={faMapMarkerAlt}>
        <a href={props.mapsUrl}>
          150 Route de la Planche Pierre <br /> 42310 Changy{" "}
        </a>
      </ContactItem>
    </div>
  </div>
)

function Contact(props) {
  const { intl, data } = props
  const contactData = data.strapiContactpage
  const mapsUrl =
    "https://www.google.com/maps/place/Sylvide/@46.1372342,3.894532,17z/data=!3m1!4b1!4m5!3m4!1s0x47f6aaaec936015b:0x631703d72b01860!8m2!3d46.1372342!4d3.894532"
  const metaTitle = intl.formatMessage({ id: "menu.contact" })
  const metaDescription = intl.formatMessage({ id: "contact.description" })
  return (
    <Layout active={ContactPage}>
      <SEO
        seo={{
          metaTitle: metaTitle,
          metaDescription: metaDescription,
          locale: intl.locale,
        }}
      />

      <div className={layoutContainer}>
        <div className={imageContainer}>
          <GatsbyImage
            image={
              contactData.headerimage.localFile.childImageSharp.gatsbyImageData
            }
            className={imageTop}
            objectFit="cover"
            objectPosition="50% 50%"
            alt=""
          />
          <img className={overlayLogo} src={logoTransparent} alt="Logo" />
          <div className={textOnImage}>
            <div className={subtitle} />
          </div>
        </div>
        <LinearBlock color="orange" oneComponent={<h1>Contact</h1>} />

        <LinearBlock
          color="white"
          leftComponent={<ContactInfo mapsUrl={mapsUrl} />}
          rightComponent={<ContactForm />}
        />
      </div>
    </Layout>
  )
}

export default injectIntl(Contact)
