// extracted by mini-css-extract-plugin
export var container = "contact-module--container--3EO3r";
export var topContainer = "contact-module--topContainer--1EO2x";
export var topImage = "contact-module--topImage--2ahmL";
export var bottomContainer = "contact-module--bottomContainer--1gyR1";
export var iconMobile = "contact-module--iconMobile--3h-w0";
export var contacts = "contact-module--contacts--3v9cv";
export var columnContainer = "contact-module--columnContainer--Znlgr";
export var map = "contact-module--map--21aeQ";
export var contactItem = "contact-module--contactItem--3a3b9";
export var contactItemContent = "contact-module--contactItemContent--Z-8PA";
export var iconDesktop = "contact-module--iconDesktop--2wpyE";
export var rowContainer = "contact-module--rowContainer--3mrPH";