import React from "react"
import { container } from "./button.module.scss"

function Button(props) {
  let { children, className } = props
  let classes = container + " " + className
  return (
    <div {...props} className={classes}>
      {children}
    </div>
  )
}

export default Button
