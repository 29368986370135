import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet"
import React from "react"

function Map(props) {
  let position = [46.13784462153977, 3.894484766396692]
  if (typeof window !== undefined) {
    return (
      <MapContainer
        center={position}
        zoom={14}
        scrollWheelZoom={false}
        className={props.className}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={position}>
          <Tooltip permanent direction="top" offset={[-15, -15]}>
            Sylvide
          </Tooltip>
        </Marker>
      </MapContainer>
    )
  }
  return <></>
}

export default Map
